import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';


import Context from "../../context/"
import ContentWrapper from "../../styles/contentWrapper"
import Underlining from "../../styles/underlining"
import { lightTheme, darkTheme } from "../../styles/theme"

import Madoff from "../../assets/ponzi/24.png"

const StyledSection = styled.section`
  width: 100%;
  height: calc(70vh);
  padding-top: 80px;

  background: ${({ theme }) => theme.colors.background};
`

const HeaderBackground = styled.img`
  position: absolute;
  width: 50%;
  height: auto;
  top: 55%;
  bottom: 25%;
  left: 25%;
  z-index: 0;

  @media (max-width: 800px) {
    top: 45%;
  }
`




const Banner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 25px;

  justify-content: center;
  align-items: center;

`

const BannerImage = styled.img`

    height: calc(1213px / 3);
    width: calc(984px / 3);
    border 1px solid rgb(244,244,244);
    border-radius: 50px;

`


const BannerSubText = styled.p`

font-size: 20px;
font-weight: 900;
font-weight: 500;
font-size: 19.6882px;
line-height: 24px;
color: #5B5B5B;


`

const AnimatedUnderlining = motion.custom(Underlining)

const Soon = () => {
  const { isIntroDone, darkMode } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const eControls = useAnimation()
  const sControls = useAnimation()
  const uControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        eControls.start({
          rotate: [0, -10, 12, -10, 9, 0, 0, 0, 0, 0, 0],
          transition: { duration: 2.5, loop: 3, repeatDelay: 1 },
        })
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
        await sControls.start({
          opacity: 1,
          x: 0,
        })
        // Animate underlining to hover state
        await uControls.start({
          boxShadow: `inset 0 -2rem 0 ${
            darkMode ? darkTheme.colors.secondary : lightTheme.colors.secondary
          }`,
          transition: { delay: 0.4, ease: "circOut" },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, eControls, gControls, sControls, uControls])

  
  return (
    <StyledSection id="hero">
      

      <Banner>

        <BannerImage src={Madoff}/>

        <BannerSubText>
        Coming soon
        </BannerSubText>

    </Banner>

    </StyledSection>
  )
}


Soon.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Soon
