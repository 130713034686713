import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';


import Context from "../../context/"
import ContentWrapper from "../../styles/contentWrapper"
import Underlining from "../../styles/underlining"
import { lightTheme, darkTheme } from "../../styles/theme"
import PonziOne from "../../assets/ponzi/23.png"
import PonziTwo from "../../assets/ponzi/24.png"
import PonziThree from "../../assets/ponzi/23.png"

import banner from "../../assets/ponzi/24.png"
import header from "../../assets/ponzi/25.png"

const StyledSection = styled.section`
  width: 100%;
  height: 100%;
  padding-top: 80px;
  background: ${({ theme }) => theme.colors.background};
`

const HeaderBackground = styled.img`
  position: absolute;
  width: 50%;
  height: auto;
  top: 55%;
  bottom: 25%;
  left: 25%;
  z-index: 0;

  @media (max-width: 800px) {
    top: 45%;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  z-index: 10;
  && {
    width: 100%;
    max-width: none;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-left: 0;
    @media (max-width: 800px) {
      align-items: center;
      width: 100%;
      padding: 0;
    }

    .greetings {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    a{
        font-size: 16px;
        :hover {
            color: blue;
        }
    }
    .emoji {
      margin-left: 0.75rem;
      width: 2.2rem;
      height: 2.2rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left: 1rem;
        width: 3rem;
        height: 3rem;
      }
    }

    .first-section {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media (max-width: 500px) {
            flex-direction: column;
            width: 100%;
          }
    }

    .first-section-2 {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 2rem;

      @media (max-width: 500px) {
          flex-direction: column-reverse;
          width: 100%;
        }
  }

    .second-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .single-text {
        width: 100%;
        font-size: 22px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 1.5px;
    }
    .single-image {
        width: auto;
        max-height: 300px;
        border: 1px solid transparent;
        border-radius: 5px;
    }

    .default-text {
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 1.5px;
        color: #6A6A6A;
        @media (max-width: 500px) {
            width: 100%;
          }

          a {
            color: black !important;
            font-weight: 500;
            text-decoration-line: underline;

          }
          a:hover{
            color: #6A6A6A !important;
          }
    }
    .default-image {
        width: auto;
        max-height: 300px;
        margin-left: auto;
        border: 1px solid transparent;
        border-radius: 5px;
        @media (max-width: 500px) {
            width: 80%;
            height: auto;
          }
    }

    .default-image-2 {
      width: auto;
      max-height: 300px;
      margin-right: auto;
      border: 1px solid transparent;
      border-radius: 5px;
      @media (max-width: 500px) {
          width: 80%;
          height: auto;
        }
  }

    .title {
      width: 50%;
      margin-bottom: 3rem;
      margin-top: 3rem;
      font-weight: 900;
      font-size: 36px;
      line-height: 54px;
      letter-spacing: -0.06em;
      border-radius: 5px;
      @media (max-width: 500px) {
          width: 100%;
        }
      
    }

    .title-main {
      margin-bottom: 3rem;
      margin-top: 3rem;
      width: 100%;

      font-weight: 900;
      font-size: 36px;
      line-height: 54px;
      letter-spacing: -0.06em;
    }
    .subtitle {
      margin-top: -0.75rem;
    }
    .description {
      font-size: 1.125rem;
      margin-bottom: 2rem;
    }
  }
`

const Values = styled.div`
  z-index: 10;
  width: 50%;
  height: 100%;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 0;
  margin-right: 40px;
  margin-left: auto;
  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    width: 100%;
  }
`

const ValuesWrapper = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 40px;

  @media (max-width: 800px) {
    margin-left: 0px;
  }
`

const ValueText = styled.p`
  font-size: 18px;
  font-weight: 700;
  z-index: 10;
`
const DisplayValue = styled.p`
  font-size: 18px;
  z-index: 10;
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 1200px;

  @media (max-width: 800px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`

const BuyButton = styled.a`
    width: ${({ width }) => (width ? width : "auto")};
    height: auto;
    display: flex;
    flex-direction: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.background};
    background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primary} 50%,
    ${({ theme }) => theme.colors.background} 50%
    );
    background-size: 205% 100%;
    background-position: right bottom;
    border-radius: 5px;
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    padding: ${({ padding }) => (padding ? padding : ".3rem 1.25rem")};
    transition: all 0.1s ease-out;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
    background-position: left bottom;
    color: white !important;
    }

    margin-top: 1rem;

    width: 130px;
    text-align: center;
    
`

const LineBreak = styled.div`

margin-top: 20px;

`

const Banner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: url(${banner});
  background-size: cover;
  height: 700px;
  padding-top: 25px;

  justify-content: center;
  align-items: center;

`

const BannerImage = styled.img`

    height: 433px;
    width: 433px;

`

const BannerText = styled.a`

font-size: 38px;
text-decoration-line: underline;
font-weight: 900;

:hover {
  color: #5B5B5B;
}


`

const BannerSubText = styled.p`

font-size: 20px;
font-weight: 900;
font-weight: 500;
font-size: 19.6882px;
line-height: 24px;
color: #5B5B5B;


`

const AnimatedUnderlining = motion.custom(Underlining)

const Main = () => {
  const { isIntroDone, darkMode } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const eControls = useAnimation()
  const sControls = useAnimation()
  const uControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        eControls.start({
          rotate: [0, -10, 12, -10, 9, 0, 0, 0, 0, 0, 0],
          transition: { duration: 2.5, loop: 3, repeatDelay: 1 },
        })
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
        await sControls.start({
          opacity: 1,
          x: 0,
        })
        // Animate underlining to hover state
        await uControls.start({
          boxShadow: `inset 0 -2rem 0 ${
            darkMode ? darkTheme.colors.secondary : lightTheme.colors.secondary
          }`,
          transition: { delay: 0.4, ease: "circOut" },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, eControls, gControls, sControls, uControls])

  
  return (
    <StyledSection id="hero">
      

      <Banner>

        <BannerImage src={header}/>
        <BannerText href={'#'}>

          Text

        </BannerText>

        <BannerSubText>
        Text
        </BannerSubText>

</Banner>

      <SectionWrapper>
        <StyledContentWrapper>
            <div className="title-main">

              <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={gControls}
                    data-testid="animated-heading"
                >
              <div className="first-section">

              <h1 className="title">
              Text  <br/>
              Text

              <p className="default-text">
              Text
                </p>
              </h1>
              <img className="default-image" src={PonziOne}/>

              </div>
              </motion.div>


              <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={gControls}
                    data-testid="animated-heading"
                >

            <div className="first-section-2">


<img className="default-image-2" src={PonziTwo}/>
<h1 className="title">
Text
<p className="default-text">
Text
  </p>
</h1>
</div>

            </motion.div>

            <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={gControls}
                    data-testid="animated-heading"
                >

            <div className="first-section">

<h1 className="title">
Text

<p className="default-text">
<b>Step 1</b>: Download and set up a compatible wallet, such as MetaMask or Trust Wallet.<LineBreak/>
            <b>Step 2</b>: Purchase Ethereum (ETH) on a cryptocurrency exchange and transfer it to your wallet.<LineBreak/>
            <b>Step 3</b>: <a href="#" target="_blank">Buy Text</a><LineBreak/>

            *Please note that trading fees and slippage may apply during the purchase process. We recommend setting slippage to a low value like 0.5%.

  </p>
</h1>
<img className="default-image" src={PonziThree}/>

</div>

            </motion.div>

            <h1 className="title">
                Roadmap
            </h1>

            <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={gControls}
                    data-testid="animated-heading"
                >
            <div className="second-section">
            <p className="single-text">
            <b> 1</b>: Text<LineBreak/>
            <b> 2</b>: Text<LineBreak/>
            <b> 3</b>: Text t<LineBreak/>
            *Text
            </p>
            

            </div>
            </motion.div>

            <h1 className="title">
            Text
            </h1>


            <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={gControls}
                    data-testid="animated-heading"
                >


        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="PonziToken"
          options={{height: 400}}
        />

</motion.div>



<h1 className="title">
                Important links
            </h1>

            <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={gControls}
                    data-testid="animated-heading"
                >
            <div className="second-section">

            <p className="single-text">
            <BuyButton href="#" target="_blank" style={{width: '175px', marginTop: '0'}}>Coinmarketcap</BuyButton><LineBreak/>
            <BuyButton href="#" target="_blank" style={{width: '135px'}}>Etherscan</BuyButton><LineBreak/>

            </p>

            <iframe height="400px" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src={`https://www.geckoterminal.com/eth/pools/0x1b9fd5d7d1168bb6d21504eff2b0330d5c42ce0b?embed=1&info=0&swaps=0`} allow="clipboard-write" allowFullScreen></iframe>

            </div>

            </motion.div>


            </div>
        </StyledContentWrapper>
      </SectionWrapper>
    </StyledSection>
  )
}


Main.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Main
